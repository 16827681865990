import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import Layout from './Layout'
import DoiTac from './components/DoiTac'
import SearchComponents from './components/SearchComponents'
import Ads from './components/Ads'
import { useEffect, useState } from 'react'
import DataComPlugin from './components/DataComPlugin'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import DataComSearchPlugin from './components/DataComSearchPlugin'

const Search = () => {
  const { location } = useRouter()
  console.log(location.search);
  const dispatch = useDispatch<AppDispatch>()
  const [a,setA] = useState(false);

  // useEffect(() => {
  //   // window.location.reload()
  //   setA(!a)
  // }, [a])

  console.log(13213);

  return (
    <Layout>
      {/* <SearchComponents /> */}
      <div className="container-custom py-5">
        <DataComSearchPlugin />
        
        {/* <div id="dtc-search"></div>
        <div id='dtc-result'></div> */}
        <DataComPlugin />
      </div>
      <Ads />
      <DoiTac />
    </Layout>
  )
}

export default Search
