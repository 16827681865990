import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react-pro';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import SModal from '../../shared/Modal/SModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { AppDispatch } from '../../../store';
import { ToastSuccess } from '../../shared/toast/Toast';
import { createEntity, updateEntity } from './veManagement.api';
import { fetching, resetEntity } from './veManagement.reducer';
import { INewVe, IVe } from '@/shared/model/ve.model';
import CustomFormikEditor from '@/components/shared/CustomEditor/CustomEditor';

interface IUserUpdateProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  userObj?: IVe;
  filghtId?: number;
}

const UserUpdate = (props: IUserUpdateProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector((state: RootState) => state.veReducer);
  const { updateEntitySuccess } = initialState;
  const { visible, setVisible, userObj, filghtId } = props;
  const handleOnClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (updateEntitySuccess) {
      setVisible(false);
      ToastSuccess(userObj ? 'Cập nhật vé thành công' : 'Thêm vé thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  const initialValues: INewVe = {
    flightId: filghtId ?? 0,
    soLuong: 0,
    hang: 'M',
    price: 0,
    content: '',
  };

  const validationSchema = Yup.object().shape({
    soLuong: Yup.number().required('Không được để trống'),
    hang: Yup.string().trim().required('Không được để trống'),
    price: Yup.number().required('Không được để trống'),
  });

  return (
    <SModal visible={visible} onClose={handleOnClose} size="lg" backdrop="static" className="custom-modal">
      <Formik
        initialValues={userObj || initialValues}
        validationSchema={validationSchema}
        // validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(value) => {
          dispatch(fetching());
          if (userObj) {
            dispatch(updateEntity(value as IVe));
          } else {
            dispatch(createEntity(value));
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue }) => (
          <CForm onSubmit={handleSubmit} className="custom-form">
            <>
              <CModalHeader>{userObj ? 'Chỉnh sửa vé' : 'Thêm vé'}</CModalHeader>
              <CModalBody>
                <CRow className="g-3">
                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Số lương vé</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="soLuong"
                      autoComplete="off"
                      value={values.soLuong}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.soLuong && touched.soLuong}
                    />
                    <CFormFeedback invalid className={!!errors.soLuong && touched.soLuong ? 'd-block' : 'd-none'}>
                      {errors.soLuong}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Hàng ghế</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="hang"
                      autoComplete="off"
                      value={values.hang}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.hang && touched.hang}
                    />
                    <CFormFeedback invalid className={!!errors.hang && touched.hang ? 'd-block' : 'd-none'}>
                      {errors.hang}
                    </CFormFeedback>
                  </CCol>


                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Giá ghế</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="price"
                      autoComplete="off"
                      value={values.price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.price && touched.price}
                    />
                    <CFormFeedback invalid className={!!errors.price && touched.price ? 'd-block' : 'd-none'}>
                      {errors.price}
                    </CFormFeedback>
                  </CCol>


                  <CCol xs="12">
                    <CFormLabel className="mb-6 label-gray-700">Nội dung</CFormLabel>
                    <CustomFormikEditor
                      simpleMode
                      size="sm"
                      name="content"
                      value={values.content}
                      setFieldValue={setFieldValue}
                    />
                    <CFormFeedback invalid className={!!errors.price && touched.price ? 'd-block' : 'd-none'}>
                      {errors.price}
                    </CFormFeedback>
                  </CCol>
                  
                </CRow>
              </CModalBody>
              <CModalFooter className="d-flex justify-content-end">
                <CButton
                  className="btn-custom minw-120 variant-gray-300"
                  type="button"
                  onClick={() => {
                    resetForm();
                    setVisible(false);
                  }}
                >
                  Huỷ
                </CButton>
                <CButton className="btn-custom minw-120 primary-500" type="submit">
                  Lưu
                </CButton>
              </CModalFooter>
            </>
          </CForm>
        )}
      </Formik>
    </SModal>
  );
};

export default UserUpdate;
