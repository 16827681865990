import { CCol, CRow } from "@coreui/react-pro";
import Slider from "react-slick"

const BoxChuyenBayPhoBien = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        padding: 10,
        margin: 10,
    };

    const arrayData = [
        { 
            createdDate: '01/01/2022',
            noiDi: 'TP Hồ Chí Minh (SGN)',
            noiDen: 'Hà Nội (HAN)',
            ngayDi: '01/01/2022',
            priceSale: '1.000.000',
         },
         { 
            createdDate: '01/01/2022',
            noiDi: 'TP Hồ Chí Minh (SGN)',
            noiDen: 'Hà Nội (HAN)',
            ngayDi: '01/01/2022',
            priceSale: '1.000.000',
         },
         { 
            createdDate: '01/01/2022',
            noiDi: 'TP Hồ Chí Minh (SGN)',
            noiDen: 'Hà Nội (HAN)',
            ngayDi: '01/01/2022',
            priceSale: '1.000.000',
         },
         { 
            createdDate: '01/01/2022',
            noiDi: 'TP Hồ Chí Minh (SGN)',
            noiDen: 'Hà Nội (HAN)',
            ngayDi: '01/01/2022',
            priceSale: '1.000.000',
         }
    ]

    return (

        <div className="box-chuyen-bay-pho-bien py-5" style={{ background: '#f0f8ff' }}>

            <div className="container-custom">

                <h2 className="text-center fw-bold">Chuyến bay phổ biến</h2>

                <CRow className="mt-3 g-3">
                    {
                        arrayData.map((item, index) => {
                            return (
                                <CCol md={3} key={index}>

                                    <div className="item" key={index}>

                                        <div className="p-4" style={{ background: '#fff' }}>
                                            <div className="position-relative">

                                                <div style={{ fontSize: 13 }}>Đã xem: 23 giờ 36 phút 35 giây trước</div>
                                                <div className="fw-bold">{item?.noiDi}</div>
                                                <div style={{ fontSize: 13 }}>đến</div>
                                                <div className="fw-bold">{item?.noiDen}</div>
                                                <div style={{ fontSize: 13 }}>Ngày đi: 18/12/2024</div>


                                                <div className="mt-3">
                                                    <div style={{ fontSize: 13 }}>chỉ từ (VND)</div>
                                                    <div className="fw-bold" style={{ fontSize: 24, color: "#ec2029" }}>{item?.priceSale}</div>
                                                    <div style={{ fontSize: 13 }}>Một chiều</div>
                                                </div>


                                                <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                                                    <a className="text-decoration-none d-flex align-items-center justify-content-center more" style={{ background: '#2cace2', width: 40, height: 40, borderRadius: '50%' }}>
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path d="M10.0007 3.33325L8.82565 4.50825L13.4757 9.16658H3.33398V10.8333H13.4757L8.82565 15.4916L10.0007 16.6666L16.6673 9.99992L10.0007 3.33325Z" fill="#00558F"></path>
                                                        </svg>
                                                    </a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>

                                </CCol>
                                
                            )
                        })
                    }
                </CRow>
                <div className="m-3" style={{ fontSize: 14, fontStyle: 'italic'  }}>
                    <p>*Giá vé hiển thị được thu thập trong vòng 48 giờ và có thể không còn hiệu lực tại thời điểm đặt chỗ. Chúng tôi có thể thu thêm phí và lệ phí cho một số sản phẩm và dịch vụ.</p>
                </div>

            </div>



        </div>
    )
}

export default BoxChuyenBayPhoBien