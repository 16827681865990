import React from "react";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import banner1 from '../../../../assets/img/web/banner/1.jpg'
import banner2 from '../../../../assets/img/web/banner/2.jpg'
import banner3 from '../../../../assets/img/web/banner/3.jpg'
import banner4 from '../../../../assets/img/web/banner/4.jpg'

const array = [banner1, banner2, banner3, banner4]
const CustomNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: 'gray',
          borderRadius: '50%',
        }}
        onClick={onClick}
      >
        <BsChevronRight size={24} color="red" />
      </div>
    );
  };


  const CustomPrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: 'gray',
          borderRadius: '50%',
        }}
        onClick={onClick}
      >
        <BsChevronLeft size={24} color="red" />
      </div>
    );
  };
export default function Banner() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // nextArrow: <CustomNextArrow />,
    // prevArrow: <CustomPrevArrow />
  };
  return (
    <Slider {...settings} className="banner">  
        {
            array.map((item, index) => {
                return (
                    <div className="item">
                        <img src={item} />
                    </div>
                )
            })
        }
    </Slider>
  );
}