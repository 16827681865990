import Slider from "react-slick"
import doitac1 from '../../../../assets/img/web/doitac/1.png'
import doitac2 from '../../../../assets/img/web/doitac/2.png'
import doitac3 from '../../../../assets/img/web/doitac/3.png'
import doitac4 from '../../../../assets/img/web/doitac/4.png'
import doitac5 from '../../../../assets/img/web/doitac/5.png'
import doitac6 from '../../../../assets/img/web/doitac/6.png'
import doitac7 from '../../../../assets/img/web/doitac/7.png'
import doitac8 from '../../../../assets/img/web/doitac/8.png'
import doitac9 from '../../../../assets/img/web/doitac/9.png'
import doitac10 from '../../../../assets/img/web/doitac/10.png'
import doitac11 from '../../../../assets/img/web/doitac/11.png'

const array = [doitac1, doitac2, doitac3, doitac4, doitac5, doitac6, doitac7, doitac8, doitac9, doitac10, doitac11]
const DoiTac = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        padding: 10,
        margin: 10,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 10,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 8,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1
              }
            }
          ]
    };

    const arrayData = [
        { 
            createdDate: '01/01/2022',
            noiDi: 'TP Hồ Chí Minh (SGN)',
            noiDen: 'Hà Nội (HAN)',
            ngayDi: '01/01/2022',
            priceSale: '1.000.000',
         },
         { 
            createdDate: '01/01/2022',
            noiDi: 'TP Hồ Chí Minh (SGN)',
            noiDen: 'Hà Nội (HAN)',
            ngayDi: '01/01/2022',
            priceSale: '1.000.000',
         },
         { 
            createdDate: '01/01/2022',
            noiDi: 'TP Hồ Chí Minh (SGN)',
            noiDen: 'Hà Nội (HAN)',
            ngayDi: '01/01/2022',
            priceSale: '1.000.000',
         }
    ]

    return (

        <div className="box-chuyen-bay-pho-bien py-5" style={{ background: '#fff' }}>

            <div className="container-custom">

                <h2 style={{ color: '#003c71' }} className="text-center fw-bold">Đối tác và thương hiệu chiến lược</h2>
                <p style={{ fontSize: 14 }} className="text-center">Những đối tác hàng không toàn cầu sẽ chắp cánh đưa bạn đến mọi địa điểm trên thế giới.</p>

                
                <Slider {...settings} className="banner">  
                    {
                        array.map((item, index) => {
                            return (
                                <div className="item p-3" key={index}>
                                    <img className="w-100" src={item} style={{ height: "100%", width: "auto", margin: "0 auto", display: "block" }} />
                                </div>
                            )
                        })
                    }
                </Slider>

         

            </div>



        </div>
    )
}

export default DoiTac