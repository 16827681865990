import Slider from "react-slick"

const KhachHang = () => {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        padding: 10,
        margin: 10,
    };

    const arrayData = [
        { 
            content: 'Mình đã đặt vé trên Đại lý vé đi nước ngoài,rất hài lòng với cách làm việc của các bạn đó là chắc chắn',
            name: 'Phạm Thúy Quỳnh',
            city: 'Nghệ An',
         },

         { 
            content: 'Mình đã đặt vé trên Đại lý vé đi nước ngoài,rất hài lòng với cách làm việc của các bạn đó là chắc chắn',
            name: 'Phạm Thúy Quỳnh',
            city: 'Nghệ An',
         },

         { 
            content: 'Mình đã đặt vé trên Đại lý vé đi nước ngoài,rất hài lòng với cách làm việc của các bạn đó là chắc chắn',
            name: 'Phạm Thúy Quỳnh',
            city: 'Nghệ An',
         },


         { 
            content: 'Mình đã đặt vé trên Đại lý vé đi nước ngoài,rất hài lòng với cách làm việc của các bạn đó là chắc chắn',
            name: 'Phạm Thúy Quỳnh',
            city: 'Nghệ An',
         },


    ]

    return (
        <div className="khach-hang py-5">
            <div className="container-custom">
                <h2 className="text-center fw-bold" style={{ color: "#003c71" }}>Khách hàng nói về chúng tôi</h2>


                <Slider {...settings} className="banner text-center">  
                    {
                        arrayData.map((item, index) => {
                            return (
                                <div className="item p-3 position-relative" key={index} style={{ maxWidth: 1168 }}>

                                    <div style={{ color: "#646464" }}>{item.content}</div>
                                    <div className="mt-5 mb-2" style={{ fontSize: 18, fontWeight: 900, color: "#000" }}>{item.name}</div>
                                    <div>{item.city}</div>

                                    <div>
                                        <i className="fas fa-quote-right" style={{ color: "#26bed6", fontSize: 60, opacity: 0.5, position: 'absolute', right: 0, bottom: 0 }}></i>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>

                
            </div>
        </div>
    )
}

export default KhachHang