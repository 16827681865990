import { useDispatch } from 'react-redux'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { AppDispatch } from '@/store'
import Header from './components/Header';
import Footer from './components/Footer';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { navigate, location } = useRouter()
  const dispatch = useDispatch<AppDispatch>()
  return (
    <>
      <Header />
        {children}
      <Footer />
    </>
  )
}

export default Layout
