import { AppDispatch } from "@/store";
import { useDispatch, useSelector } from "react-redux";
import { getEntity } from "./flightManagement.api";
import { useEffect, useState } from "react";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { selectEntityById } from "./flightManagement.reducer";
import { CButton, CFormInput, CInputGroup, CInputGroupText, CRow, CSmartTable } from "@coreui/react-pro";
import PlusIcon from "@/components/shared/icons/PlusIcon";
import { RootState } from "@/reducers";
import { IVe } from "@/shared/model/ve.model";
import { fetching, resetEntity, setFilterState, veSelectors } from "../VeManagement/veManagement.reducer";
import { createIndexes, formatVND } from "@/shared/utils/ultils";
import { ToastSuccess } from "@/components/shared/toast/Toast";
import { getEntities, removeEntity } from "../VeManagement/veManagement.api";
import SearchIcon from "@/components/shared/icons/SearchIcon";
import FilterIcon from "@/components/shared/icons/FilterIcon";
import CustomTableFooter from "@/components/shared/CustomTableFooter/CustomTableFooter";
import UserUpdate from "../VeManagement/VeUpdate";
import Edit02Icon from "@/components/shared/icons/Edit02Icon";
import TrashIcon from "@/components/shared/icons/TrashIcon";

interface IIndexUser extends IVe {
    index: number;
}

const columns = [
    {
      key: 'id',
      label: 'ID',
      sorter: false,
    },
    {
        key: 'soLuong',
        label: 'Số lượng',
        sorter: false,
      },
      {
        key: 'hang',
        label: 'Hàng',
        sorter: false,
      },
      {
        key: 'price',
        label: 'Giá',
        sorter: false,
      },
    {
      key: 'actions',
      label: 'Thao tác',
      sorter: false,
      _props: { className: 'text-center' },
    },
  ];

const ListVeManagement = () => {
    const { params } = useRouter();
    const dispatch = useDispatch<AppDispatch>()
    const { id } = params;
    const { initialState } = useSelector((state: RootState) => state.veReducer);
    const { filterState, totalItems, totalPages, loading, updateEntitySuccess, deleteEntitySuccess } = initialState;
    const [createUser, setCreateUser] = useState<boolean>(false);
    const [updateUser, setUpdateUser] = useState<IVe | null>(null);

    const handleCreateUser = () => {
        setCreateUser(true);
    };

    const handleUpdateUser = (user: IVe) => () => {
        setUpdateUser(user);
    };

    const handleCancelUpdateUser = (update?: boolean) => {
        setUpdateUser(null);
    };

    const handlePaginationChange = (page: number) => {
        if (page !== 0) {
        window.scrollTo(0, 0);
        dispatch(setFilterState({ ...filterState, page: page }));
        }
    };

    const sanbay = useSelector(veSelectors.selectAll);
    const indexedSanBay = createIndexes(sanbay, filterState);

    useEffect(() => {
        if (updateEntitySuccess && !createUser && !updateUser) {
            ToastSuccess('Cập nhật trạng thái thành công');
            dispatch(resetEntity());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateEntitySuccess]);

    useEffect(() => {
        if (!deleteEntitySuccess) {
        dispatch(fetching());
        dispatch(getEntities(filterState));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(filterState), deleteEntitySuccess]);

    useEffect(() => {
        dispatch(setFilterState({ ...filterState, flightId: Number(id) }));
    }, [id]);

    useEffect(() => {
        if (deleteEntitySuccess) {
        ToastSuccess('Xoá vé thành công');
        dispatch(resetEntity());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteEntitySuccess]);

    const [chosenDelete, setChosenDelete] = useState<IVe | null>(null);

    const handleDelete = () => {
        if (chosenDelete) {
        dispatch(removeEntity(chosenDelete?.id));
        setChosenDelete(null);
        }
    };

    const handleCancelDelete = () => {
        setChosenDelete(null);
    };

    const [keyword, setKeyword] = useState<string>('');

    const onSearch = () => {
        // dispatch(getEntities({ ...filterState, keyword }));
    }
    
    

    useEffect(() => {
        if(id){
            dispatch(getEntity(Number(id)));
        }
    }, [id])

    const data = useSelector(selectEntityById(Number(id)))
    
    return <div className="tab-outlet-content">
        <div className="table-context">
          <div>
            <h4>Danh sách vé của chuyến bay: <strong>{data?.maChuyenBay}</strong></h4>
            <div>Từ <strong>{data?.noiDi?.name}</strong> đến <strong>{data?.noiDen?.name}</strong></div>
          </div>
          <div>
            <CButton className="btn-custom primary-500 btn-sm" onClick={handleCreateUser}>
              <PlusIcon /> Thêm vé
            </CButton>
          </div>
        </div>

        <div className="table-content">
          <CSmartTable
            header
            columns={columns}
            items={indexedSanBay}
            clickableRows
            noItemsLabel="Không có bản ghi"
            itemsPerPage={filterState.limit}
            scopedColumns={{
                name: ({ index, ...value }: IIndexUser) => (
                  <td>
                    {/* <div className="">Tên chuyến bay: <strong>{value.tenChuyenBay}</strong></div>
                    <div className="">Mã chuyến bay: <strong>{value.maChuyenBay}</strong></div> */}
                    </td>
                ),
                price: ({ index, ...value }: IIndexUser) => (
                    <td>
                        {formatVND(value.price)}
                    </td>
                ),
               
              // avatar: ({ index, ...value }: IIndexUser) => (
              //   <td>
              //     <CAvatar src={avatar} size="sm" />
              //   </td>
              // ),
              // status: ({ index, ...value }: IIndexUser) => (
              //   <td>
              //     <div className="d-flex align-items-center flex-nowrap">
              //       <CFormSwitch
              //         checked={value.status === ResponseStatus.ACTIVE}
              //         className="me-2"
              //         onChange={handleUpdateStatus(value)}
              //         disabled={loading}
              //       />
              //       {mapResponseStatus[value.status]}
              //     </div>
              //   </td>
              // ),
              // money: ({ index, ...value }: IIndexUser) => (
              //   <td>{value.money ? new Intl.NumberFormat('vi-VN').format(value?.money || 0) : ''}</td>
              // ),
              // addMoney: ({ index, ...value }: IIndexUser) => (
              //   <td>{value.addMoney ? new Intl.NumberFormat('vi-VN').format(value?.addMoney || 0) : ''}</td>
              // ),
              actions: ({ index, ...value }: IIndexUser) => (
                <td>
                  <CInputGroup className="custom-input-group-action justify-content-center">
                    <CInputGroupText onClick={handleUpdateUser(value)}>
                      <Edit02Icon />
                    </CInputGroupText>
                    <CInputGroupText onClick={() => setChosenDelete(value)}>
                        <TrashIcon />
                      </CInputGroupText>
                  </CInputGroup>
                </td>
              ),
            }}
            tableProps={{
              hover: true,
              align: 'middle',
              responsive: 'lg',
              className: 'custom-table',
            }}
            tableHeadProps={{
              className: 'table-head',
            }}
            tableBodyProps={{
              className: 'table-body',
            }}
          />
        </div>

        {createUser ? <UserUpdate visible={createUser} filghtId={Number(id)} setVisible={setCreateUser} /> : ''}
        {updateUser ? (
          <UserUpdate visible={Boolean(updateUser)} userObj={updateUser} setVisible={handleCancelUpdateUser} />
        ) : (
          ''
        )}
        <CRow>
          <CustomTableFooter
            totalItems={totalItems}
            hideSideChosen
            totalPages={totalPages}
            filterState={filterState}
            setFilterState={(filter) => dispatch(setFilterState(filter))}
            handlePaginationChange={handlePaginationChange}
          />
        </CRow>

    </div>
};
export default ListVeManagement;