import { CCol, CRow } from "@coreui/react-pro"
import khuyenmai1 from '../../../../assets/img/web/khuyenmai/1.jpg'
import khuyenmai2 from '../../../../assets/img/web/khuyenmai/2.jpg'
import khuyenmai3 from '../../../../assets/img/web/khuyenmai/3.jpg'
import khuyenmai4 from '../../../../assets/img/web/khuyenmai/4.jpg'

import book from '../../../../assets/img/web/book.svg'
import map from '../../../../assets/img/web/map.svg'

const BoxKhuyenMaiDuocYeuThich = () => {

    const array = [
        {
            img: khuyenmai1, title: 'Green Friday Bay Khắp Thế Giới Làm Mới Chính Mình', path: '/'
        },
        {
            img: khuyenmai2, title: 'Bay khắp thế giới làm mới chính mình', path: '/'
        },
        {
            img: khuyenmai3, title: 'Miễn phí 20kg hành lý ký gửi - Kỷ niệm 10 năm Đài Loan', path: '/'
        },
        {
            img: khuyenmai4, title: 'MUA VÉ MÁY BAY TẾT ẤT TỴ 2025 CÙNG VIETNAM AIRLINES', path: '/'
        },
       ]
    return <>
        <div className="box-khuyen-mai-duoc-yeu-thich py-5">

            <div className="container-custom">
                <CRow className="g-4">
                    <CCol md={4}>

                        <h3 className="fw-bold">Khuyến mãi được yêu thích</h3>

                        <p>Khuyến mãi của các hãng hàng không rất quan trọng với chúng tôi cũng như với quý khách. Đó là lý do tại sao chúng tôi luôn đồng hành để giúp quý khách cập nhật Khuyến mãi của các hãng. Hãy để chúng tôi truyền cảm hứng cho quý khách với các ưu đãi đặc biệt và thú vị.</p>

                        <div className="d-flex flex-column">
                            <a className="text-decoration-none text-black d-flex align-items-center gap-2" style={{ fontSize: 17 }} href="#">
                                <img src={book} />
                                <div>Lấy cảm hứng với <strong>Blog</strong>, tin du lịch của chúng tôi</div></a>
                            <a className="text-decoration-none text-black d-flex align-items-center gap-2 mt-2" style={{ fontSize: 17 }} href="#">
                                <img src={map} />
                                Xem thêm Khuyến mãi của chúng tôi</a>
                        </div>

                    </CCol>

                    <CCol md={8}>
                       

                        <CRow>

                            {
                                array.map((item, index) => {
                                    return (
                                        <CCol md={3} xs={6}>
                                            <div className="position-relative">
                                                <img className="w-100" style={{ height: '100%', minHeight: 284 }} src={item.img} />

                                                <div className="position-absolute p-3" style={{ bottom: 0, left: 0, right: 0, background: 'linear-gradient(180deg,rgba(19,28,39,0) 0,rgba(9,14,19,.5) 39.32%,rgba(0,0,0,.9) 100%)' }}>

                                                    <div className="text-center text-white" style={{ fontWeight: 'bold' }}>{item.title}</div>

                                                    <div className="d-flex justify-content-center">
                                                        <a href={item.path} className="text-decoration-none text-white" style={{ fontWeight: 'bold' }}>Xem thêm</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </CCol>
                                    )
                                })
                            }
                            
                        </CRow>

                    </CCol>
                </CRow>
            </div>

        </div>
    </>
}
export default BoxKhuyenMaiDuocYeuThich