import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import Layout from './Layout'
import Banner from './components/Banner'
import BoxKhuyenMaiDuocYeuThich from './components/BoxKhuyenMaiDuocYeuThich'
import BoxChuyenBayPhoBien from './components/BoxChuyenBayPhoBien'
import KhachHang from './components/KhachHang'
import DoiTac from './components/DoiTac'
import Ads from './components/Ads'

const Contact = () => {
  const dispatch = useDispatch<AppDispatch>()



  return (
    <Layout>
      <div className='page-contact'>
        <iframe className='w-100' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.4653528989024!2d106.6100314!3d10.775626200000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752c1437e64b53%3A0x177d5ce06e583d36!2zMzI1IMSQLiBMw6ogVsSDbiBRdeG7m2ksIELDrG5oIFRy4buLIMSQw7RuZywgQsOsbmggVMOibiwgSOG7kyBDaMOtIE1pbmggNzAwMDA!5e0!3m2!1svi!2s!4v1733026014253!5m2!1svi!2s'></iframe>
      </div>
      <Ads />
      <DoiTac />
    </Layout>
  )
}

export default Contact
