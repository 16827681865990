import { CCol, CRow } from "@coreui/react-pro";
import ads1 from '../../../../assets/img/web/1.svg'
import ads2 from '../../../../assets/img/web/2.svg'
import ads3 from '../../../../assets/img/web/3.svg'

const Ads = () => {

    const ads = [
        { image: ads1, title: 'Hơn 1 triệu vé đã bán', content: 'Vé nội địa và quốc tế, giá tốt nhất' },
        { image: ads2, title: 'Hơn 1 triệu vé đã bán', content: 'Vé nội địa và quốc tế, giá tốt nhất' },
        { image: ads3, title: 'Hơn 1 triệu vé đã bán', content: 'Vé nội địa và quốc tế, giá tốt nhất' },
    ]

    return       <div className="py-4" style={{ backgroundColor: '#f4f6f9' }}>
    <div className="container-custom">
      <CRow className="g-3">
        {
          ads.map((item, index) => {
            return <CCol md={4} key={index}>
              <div className="d-flex gap-3 align-items-center">
                <img src={item.image}/>
                <div>
                  <div>{item.title}</div>
                  <div className="mt-2" style={{ fontSize: 14, color: '#828282' }}>{item.content}</div>
                </div>
              </div>
          </CCol>
          })
        }
      </CRow>
    </div>
  </div>
}

export default Ads;