import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import Layout from './Layout'
import Banner from './components/Banner'
// import Search from './components/SearchComponents'
import BoxKhuyenMaiDuocYeuThich from './components/BoxKhuyenMaiDuocYeuThich'
import BoxChuyenBayPhoBien from './components/BoxChuyenBayPhoBien'
import KhachHang from './components/KhachHang'
import { CCol, CRow } from '@coreui/react-pro'
import DoiTac from './components/DoiTac'
import Ads from './components/Ads'
import { Link } from 'react-router-dom'
import Sidebar from './components/Sidebar'


const GioiThieu = () => {
  const dispatch = useDispatch<AppDispatch>()

  
 
  return (
    <Layout>
        <div className='py-5' style={{ backgroundColor: '#f7f7f7' }}>

            <div className='container-custom'>

                <CRow>
                    <CCol lg={3}>
                        <Sidebar />
                    </CCol>
                    <CCol lg={9}>
                        <div className="p-3" style={{ background: "#fff" }}>
                            <h3>CÔNG TY TNHH VÉ MÁY BAY ABAY VIỆT NAM</h3>
                            <p><strong>Kính gửi: Quý Đối tác, Khách hang,</strong></p>
                            <p><strong>Công ty TNHH ABAY Việt Nam</strong> xin gửi tới Quý khách lời chúc sức khỏe va lời chao trân trọng nhất!</p>

                            <p>ABAY Việt Nam được thanh lập từ 25/12/2013, giấy phép ĐKKD số: 0106400583ado Sở Kế hoạch – Đầu tư TP. Ha Nội cấp. Trong hơn 10 năm xây dựng va trưởng thanh, ABAY Việt Nam đã khẳng định la một trong những công ty có uy tín trong lĩnh vực cung cấp dịch vụ Vé máy bay, hang không Cao cấp, Du lịch va Xuất nhập cảnh tại thị trường Việt Nam va Quốc tế.</p>
                            <p>Hiện tại, ABAY Việt Nam la đại lý chính thức củaaVietnam Airlines, Bamboo Airways, Vietjet Air, Pacific Airlines, Vietravel Airlinesava trêna300ahãng hang không Quốc tế tại Việt Nam, đồng thời la hội viên củaaHiệp hội hang không Quốc tế IATA.aDịch vụ của ABAY Việt Nam được nhiều các đối tác đánh giá cao về tinh thần phục vụ, sự chuyên nghiệp khi xử lý các phát sinh va đã tin tưởng ký hợp đồng lâu dai. Đối tác của ABAY Việt Nam la: Cơ quan Nha nước, Doanh nghiệp, Liên doanh, Đại sứ quán, Tổ chức Quốc tế…Đặc biệt, ABAY Việt Nam la công ty đầu tiên của Việt Nam, đi tiên phong trong việc cung cấp dịch vụaHang không Cao cấp – Phòng chờ Thương giaa(VIP Services),akết hợp với Vietnam Airlines va 12 hãng hang không Quốc tếaở tại 5 đầu sân bayasân bay:aNội Bai, Đa Nẵng, Nha Trang, Tân Sơn Nhất va Cần Thơađem lại cho Quý khách hang của mình dịch vụ hoan hảo va những chuyến đi đáng nhớ!</p>
                            <p>Với Slogana“ABAY BAY THẬT DỄ DÀNG” toan thể lãnh đạo va nhân viên của ABAY Việt Nam luôn tuân thủ các quy trình quản trị chất lượng, luôn lắng nghe, thấu hiểu nhu cầu của Quý khách, để đưa ra những tư vấn, giải pháp hỗ trợ hiệu quả, đồng thời xây dựng những sản phẩm, dịch vụ phù hợp dễ dang, đáp ứng được nhu cầu của thị trường của Quý khác</p>
                            <p>Hotline:  <strong>1900 252591</strong></p>
                            <p>E-mail: dailyve6868@gmail.com</p>
                        </div>
                    </CCol>
                </CRow>

            </div>

        </div>
      <Ads />
      <DoiTac />
    </Layout>
  )
}

export default GioiThieu
