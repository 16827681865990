import ConfirmDeleteModal from '@/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import Edit02Icon from '@/components/shared/icons/Edit02Icon';
import { ToastSuccess } from '@/components/shared/toast/Toast';
import {
  CButton,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSmartTable,
} from '@coreui/react-pro';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { createIndexes } from '../../../shared/utils/ultils';
import { AppDispatch } from '../../../store';
import CustomTableFooter from '../../shared/CustomTableFooter/CustomTableFooter';
import FilterIcon from '../../shared/icons/FilterIcon';
import PlusIcon from '../../shared/icons/PlusIcon';
import SearchIcon from '../../shared/icons/SearchIcon';
import TrashIcon from '../../shared/icons/TrashIcon';
import UserUpdate from './FlightUpdate';
import { getEntities, removeEntity, updateEntity } from './flightManagement.api';
import { fetching, flightSelectors, resetEntity, setFilterState } from './flightManagement.reducer';
import { IFlight } from '@/shared/model/flight.model';
import { useRouter } from '@/shared/utils/hooks/useRouter';

const columns = [
  {
    key: 'id',
    label: 'ID',
    sorter: false,
  },
  {
    key: 'name',
    label: 'Chuyến bay',
    sorter: false,
  },
  {
    key: 'hangbay',
    label: 'Hãng bay',
    sorter: false,
  },
  {
    key: 'date',
    label: 'Ngày bay',
    sorter: false,
  },
  {
    key: 'giaVe',
    label: 'Giá vé rẻ nhất',
    sorter: false,
  },
  {
    key: 've',
    label: 'Vé',
    sorter: false,
  },
  {
    key: 'actions',
    label: 'Thao tác',
    sorter: false,
    _props: { className: 'text-center' },
  },
];

interface IIndexUser extends IFlight {
  index: number;
}

const UserManagement = () => {
  const {navigate} = useRouter();
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector((state: RootState) => state.usersReducer);
  const { filterState, totalItems, totalPages, loading, updateEntitySuccess, deleteEntitySuccess } = initialState;
  const [createUser, setCreateUser] = useState<boolean>(false);
  const [updateUser, setUpdateUser] = useState<IFlight | null>(null);

  const handleCreateUser = () => {
    setCreateUser(true);
  };

  const handleUpdateUser = (user: IFlight) => () => {
    setUpdateUser(user);
  };

  const handleCancelUpdateUser = (update?: boolean) => {
    setUpdateUser(null);
  };

  const handlePaginationChange = (page: number) => {
    if (page !== 0) {
      window.scrollTo(0, 0);
      dispatch(setFilterState({ ...filterState, page: page }));
    }
  };

  const sanbay = useSelector(flightSelectors.selectAll);
  const indexedSanBay = createIndexes(sanbay, filterState);

  useEffect(() => {
    if (updateEntitySuccess && !createUser && !updateUser) {
      ToastSuccess('Cập nhật trạng thái thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  useEffect(() => {
    if (!deleteEntitySuccess) {
      dispatch(fetching());
      dispatch(getEntities(filterState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), deleteEntitySuccess]);

  useEffect(() => {
    if (deleteEntitySuccess) {
      ToastSuccess('Xoá chuyến bay thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntitySuccess]);

  const [chosenDelete, setChosenDelete] = useState<IFlight | null>(null);

  const handleDelete = () => {
    if (chosenDelete) {
      dispatch(removeEntity(chosenDelete?.id));
      setChosenDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setChosenDelete(null);
  };

  const [keyword, setKeyword] = useState<string>('');

  const onSearch = () => {
    // dispatch(getEntities({ ...filterState, keyword }));
  }


  return (
    <>
      <div className="tab-outlet-content">
        <div className="table-context">
          <div className='d-flex'>
          </div>
          <div>
            <CButton className="btn-custom primary-500 btn-sm" onClick={handleCreateUser}>
              <PlusIcon /> Thêm chuyến bay
            </CButton>
          </div>
          <div className="d-none">
            <CInputGroup className="search-group me-2">
              <CInputGroupText id="search-addon">
                <SearchIcon />
              </CInputGroupText>
              <CFormInput placeholder="Tìm kiếm theo tên" aria-label="Username" aria-describedby="basic-addon1" />
            </CInputGroup>
            <CButton className="btn-custom gray-700 btn-sm">
              <FilterIcon /> Lọc
            </CButton>
          </div>
        </div>

        <div className="table-content">
          <CSmartTable
            header
            columns={columns}
            items={indexedSanBay}
            clickableRows
            noItemsLabel="Không có bản ghi"
            itemsPerPage={filterState.limit}
            scopedColumns={{
                name: ({ index, ...value }: IIndexUser) => (
                  <td>
                    <div className="">Tên chuyến bay: <strong>{value.tenChuyenBay}</strong></div>
                    <div className="">Mã chuyến bay: <strong>{value.maChuyenBay}</strong></div>
                    </td>
                ),
                hangbay: ({ index, ...value }: IIndexUser) => (
                  <td>
                    <div className="">Hãng máy bay: <strong>{value?.hangMayBay?.name}</strong></div>
                    <div className="">Tên máy bay: <strong>{value.tenMayBay}</strong></div>
                  </td>
                ),
                date: ({ index, ...value }: IIndexUser) => (
                  <td>
                    <div>Từ bay: <strong>{value?.noiDi?.name}</strong>  đến <strong>{value?.noiDen?.name} </strong></div>
                    <div className="">Ngày bay: <strong>{value?.ngayBay}</strong></div>
                    <div className="">Giờ bay: <strong>{value.ThoiGianBatDauBay} - {value.ThoiGianKetThucBay}</strong></div>
                  </td>
                ),
                ve: ({ index, ...value }: IIndexUser) => (
                  <td>
                    <div onClick={() => navigate('/admin/flight/' + value.id + '/ve')}>Chi tiết</div>
                  </td>
                ),
              // avatar: ({ index, ...value }: IIndexUser) => (
              //   <td>
              //     <CAvatar src={avatar} size="sm" />
              //   </td>
              // ),
              // status: ({ index, ...value }: IIndexUser) => (
              //   <td>
              //     <div className="d-flex align-items-center flex-nowrap">
              //       <CFormSwitch
              //         checked={value.status === ResponseStatus.ACTIVE}
              //         className="me-2"
              //         onChange={handleUpdateStatus(value)}
              //         disabled={loading}
              //       />
              //       {mapResponseStatus[value.status]}
              //     </div>
              //   </td>
              // ),
              // money: ({ index, ...value }: IIndexUser) => (
              //   <td>{value.money ? new Intl.NumberFormat('vi-VN').format(value?.money || 0) : ''}</td>
              // ),
              // addMoney: ({ index, ...value }: IIndexUser) => (
              //   <td>{value.addMoney ? new Intl.NumberFormat('vi-VN').format(value?.addMoney || 0) : ''}</td>
              // ),
              actions: ({ index, ...value }: IIndexUser) => (
                <td>
                  <CInputGroup className="custom-input-group-action justify-content-center">
                    <CInputGroupText onClick={handleUpdateUser(value)}>
                      <Edit02Icon />
                    </CInputGroupText>
                    <CInputGroupText onClick={() => setChosenDelete(value)}>
                        <TrashIcon />
                      </CInputGroupText>
                  </CInputGroup>
                </td>
              ),
            }}
            tableProps={{
              hover: true,
              align: 'middle',
              responsive: 'lg',
              className: 'custom-table',
            }}
            tableHeadProps={{
              className: 'table-head',
            }}
            tableBodyProps={{
              className: 'table-body',
            }}
          />
        </div>

        {createUser ? <UserUpdate visible={createUser} setVisible={setCreateUser} /> : ''}
        {updateUser ? (
          <UserUpdate visible={Boolean(updateUser)} userObj={updateUser} setVisible={handleCancelUpdateUser} />
        ) : (
          ''
        )}
        <CRow>
          <CustomTableFooter
            totalItems={totalItems}
            hideSideChosen
            totalPages={totalPages}
            filterState={filterState}
            setFilterState={(filter) => dispatch(setFilterState(filter))}
            handlePaginationChange={handlePaginationChange}
          />
        </CRow>
      </div>
      {chosenDelete ? (
        <ConfirmDeleteModal
          visible={Boolean(chosenDelete)}
          handleOnClose={handleCancelDelete}
          handleDelete={handleDelete}
          title="Xoá chuyến bay khỏi hệ thống"
          content={
            <>
              Xác nhận xoá chuyến bay <span className="high-light">{chosenDelete.tenChuyenBay}</span> khỏi hệ thống?
            </>
          }
        />
      ) : (
        ''
      )}
    </>
  );
};

export default UserManagement;
