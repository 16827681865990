import avatar from '@/assets/img/avatar.jpg';
import DotsVerticalIcon from '@/components/shared/icons/DotsVerticalIcon';
import MessageAlertSquareSidebar from '@/components/shared/icons/MessageAlertSquareSidebar';
import PhoneIcon from '@/components/shared/icons/PhoneIcon';
import SearchIcon from '@/components/shared/icons/SearchIcon';
import SendIcon from '@/components/shared/icons/SendIcon';
import { RootState } from '@/reducers';
import { socket } from '@/shared/config/socket';
import { IMessage } from '@/shared/model/message.model';
import { useRouter } from '@/shared/utils/hooks/useRouter';
import { AppDispatch } from '@/store';
import { CAvatar, CFormInput, CInputGroup, CInputGroupText, CSpinner, CTooltip } from '@coreui/react-pro';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleChatRoomName } from '../Chat';
import { selectEntityById } from '../chat.reducer';
import { getEntities } from './message.api';
import {
  fetching,
  initialMessageFilter,
  messageSelectors,
  resetFilterState,
  setFilterState,
  toggleMessageInfo
} from './message.reducer';
import MessageInfo from './MessageInfo';

const Message = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouter();
  const { roomId } = params;
  const { bodyWidth } = useSelector((state: RootState) => state.container);
  const { user } = useSelector((state: RootState) => state.authentication);
  const { initialState } = useSelector((state: RootState) => state.messageReducer);
  const { messageInfoShow, totalPages, filterState, loading } = initialState;
  const chatRoom = useSelector(selectEntityById(roomId || ''));
  const listMessage = useSelector(messageSelectors.selectAll);

  const listMessageRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [messageInput, setMessageInput] = useState('');

  const chatBoxWidth = messageInfoShow ? `calc(100% - ${bodyWidth * 0.25}px)` : '100%';

  const toggle = () => {
    dispatch(toggleMessageInfo(!messageInfoShow));
  };

  const isSender = (senderId: number) => senderId === Number(user?.id);

  const isSenderHasNextMessage = (msg: IMessage, index: number) =>
    index > 0 && messages[index - 1].senderId === msg.senderId;

  const isSenderHasPreviousMsg = (msg: IMessage, index: number) =>
    index + 1 < messages.length && messages[index + 1].senderId === msg.senderId;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && messageInput.trim()) {
      sendMessage();
    }
  };

  const scrollToBottom = () => {
    const timeout = setTimeout(() => {
      // Scroll to the bottom of the messages div
      if (listMessageRef.current) {
        listMessageRef.current.scrollTop = listMessageRef.current.scrollHeight;
      }
    }, 700);

    return () => clearTimeout(timeout);
  };

  const sendMessage = () => {
    const messageData = {
      id: chatRoom?.id,
      content: messageInput,
    };
    socket.emit('send_message', messageData);
  };

  const handleScroll = () => {
    const div = listMessageRef.current;

    if (div?.scrollTop === 0) {
      console.log('Đã đến đầu list message');
      if (totalPages > 1) dispatch(setFilterState({ ...filterState, limit: filterState.limit + 50 }));
    }
  };

  useEffect(() => {
    const div = listMessageRef.current;

    if (Number(roomId)) {
      dispatch(setFilterState({ ...initialMessageFilter, roomId: Number(roomId) }));
    }
    scrollToBottom();
    setMessages([]);

    return () => {
      dispatch(resetFilterState());
      div?.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  useEffect(() => {
    if (filterState.roomId) {
      dispatch(fetching());
      dispatch(getEntities(filterState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState)]);

  useEffect(() => {
    setMessages([...listMessage].reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(listMessage)]);

  useEffect(() => {

    const handleMessage = (newMsg: IMessage) => {
      if (Number(roomId) === newMsg.roomId) {
        setMessageInput('')
        setMessages([...messages, newMsg])
        scrollToBottom()
      }
    };

    socket.on(`user_${user?.id}_new_message`, handleMessage)

    return () => {
      socket.off(`user_${user?.id}_new_message`, handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, totalPages, roomId])

  useEffect(() => {
    const div = listMessageRef.current;
    div?.addEventListener('scroll', handleScroll);

    return () => {
      div?.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterState), totalPages]);

  if (!roomId || !chatRoom)
    return (
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <MessageAlertSquareSidebar width={80} height={80} />
        <p className="mt-2 text-gray-modern-700 text-medium-md">Chưa chọn đoạn chat nào</p>
      </div>
    );

  return (
    <>
      <MessageInfo chatRoom={chatRoom} />
      <div className="message-box" style={{ width: chatBoxWidth }}>
        <div className="chat-info-header">
          <div className="d-flex text-sm text-gray-neutral-700">
            <CAvatar src={chatRoom.avatar || avatar} className="avatar-40 me-12" />
            <div>
              <CTooltip content={handleChatRoomName(chatRoom, user, 1000)} placement="bottom" className='position-fixed'>
                <p className="m-0 mb-1 text-medium-sm text-gray-neutral-950">
                  {handleChatRoomName(chatRoom, user, 40)}
                </p>
              </CTooltip>
              <p className="m-0 text-xs text-gray-modern-500">{chatRoom.number_of_members} thành viên</p>
            </div>
          </div>
          <span className="text-gray-modern-500 text-center">
            <SearchIcon className="me-12 cursor-pointer" />
            <PhoneIcon className="me-12 cursor-pointer" />
            <DotsVerticalIcon onClick={toggle} className="cursor-pointer" />
          </span>
        </div>
        <div className="chat-box-container" ref={listMessageRef}>
          {loading ? (
            <div className="text-center">
              <CSpinner className="text-primary" />
            </div>
          ) : null}

          {messages.map((msg, index) =>
            isSender(msg.senderId) ? (
              <div
                key={`chat-${index}`}
                className="d-flex justify-content-end"
                style={{
                  marginBottom: index + 1 === messages.length ? 0 : isSenderHasPreviousMsg(msg, index) ? '4px' : '16px',
                }}
              >
                <CTooltip
                  content={dayjs(msg.timestamp).format('HH:mm DD/MM/YYYY')}
                  placement="left"
                  className="position-fixed"
                >
                  <p
                    className="chat-box primary"
                    style={{
                      marginBottom: isSenderHasPreviousMsg(msg, index) ? 0 : '4px',
                    }}
                  >
                    {msg.content}
                  </p>
                </CTooltip>
              </div>
            ) : (
              <div
                key={`chat-${index}`}
                className="d-flex align-items-end"
                style={{
                  marginBottom: index + 1 === messages.length ? 0 : isSenderHasPreviousMsg(msg, index) ? '4px' : '16px',
                }}
              >
                <div className="avatar-32 me-2">
                  {isSenderHasPreviousMsg(msg, index) ? null : (
                    <CAvatar src={msg.sender?.avatar || avatar} className="avatar-32" />
                  )}
                </div>
                <div>
                  {isSenderHasNextMessage(msg, index) || chatRoom.number_of_members === 2 ? null : (
                    <p className="text-xs text-gray-modern-500 mb-1">{msg.sender?.fullName || msg.sender?.username}</p>
                  )}
                  <CTooltip
                    content={dayjs(msg.timestamp).format('HH:mm DD/MM/YYYY')}
                    placement="left"
                    className="position-fixed"
                  >
                    <p className="chat-box m-0">{msg.content}</p>
                  </CTooltip>
                </div>
              </div>
            )
          )}
        </div>
        <div className="chat-input-container">
          <CInputGroup className="input-start-group">
            <CFormInput
              placeholder="Viết tin nhắn..."
              name="content"
              autoComplete="off"
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            {messageInput.trim() ? (
              <CInputGroupText onClick={sendMessage} className="cursor-pointer">
                <SendIcon />
              </CInputGroupText>
            ) : null}
          </CInputGroup>
        </div>
      </div>
    </>
  );
};

export default Message;
