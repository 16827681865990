export enum ResponseStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export const responseStatusArray: ResponseStatus[] = [
  ResponseStatus.ACTIVE,
  ResponseStatus.INACTIVE,
  ResponseStatus.DELETED,
];

export const mapResponseStatus: { [key in ResponseStatus]: string } = {
  [ResponseStatus.ACTIVE]: 'Hoạt động',
  [ResponseStatus.INACTIVE]: 'Không hoạt động',
  [ResponseStatus.DELETED]: 'Đã xóa',
};

export const mapResponseStatusToColor: { [key in ResponseStatus]: string } = {
  [ResponseStatus.ACTIVE]: 'success',
  [ResponseStatus.INACTIVE]: 'danger',
  [ResponseStatus.DELETED]: 'gray',
};



export enum ResponseStatusDraw {
  open = 'open',
  draw = 'drawn',
  closed = 'closed',
}

export const mapResponseStatusToColorDraw: { [key in ResponseStatusDraw]: string } = {
  [ResponseStatusDraw.open]: 'success',
  [ResponseStatusDraw.draw]: 'gray',
  [ResponseStatusDraw.closed]: 'danger',
};

export const mapResponseStatusDraw: { [key in ResponseStatusDraw]: string } = {
  [ResponseStatusDraw.open]: 'Đã mở',
  [ResponseStatusDraw.draw]: 'Chưa mở',
  [ResponseStatusDraw.closed]: 'Đã đóng',
};


export enum ResponseStatusTransaction {
  pending = 0,
  success = 1,
  error = 2,
}

export const mapResponseStatusToColorTransaction: { [key in ResponseStatusTransaction]: string } = {
  [ResponseStatusTransaction.success]: 'success',
  [ResponseStatusTransaction.pending]: 'primary',
  [ResponseStatusTransaction.error]: 'danger',
};

export const mapResponseStatusTransaction: { [key in ResponseStatusTransaction]: string } = {
  [ResponseStatusTransaction.pending]: 'Đang chờ',
  [ResponseStatusTransaction.success]: 'Thành công',
  [ResponseStatusTransaction.error]: 'Thất bại',
};


// export enum typeTransaction {
//   deposit = 1, // Nạp tiền
//   withdraw = 2, // Rút tiền
// }

// export enum statusTransaction {
//   pendding = 0, // Đang chờ
//   success = 1, // Thành công
//   error = 2, // Thất bại
// }



export enum ResponseVotedHistory {
  TANGLEN = 1,
  GIAMBOT = 2,
  GAPDOI = 3,
  MOTNUA = 4,
}

export const mapResponseColorVotedHistory: { [key in ResponseVotedHistory]: string } = {
  [ResponseVotedHistory.TANGLEN]: 'Tăng lên',
  [ResponseVotedHistory.GIAMBOT]: 'Giảm bớt',
  [ResponseVotedHistory.GAPDOI]: 'Gấp đôi',
  [ResponseVotedHistory.MOTNUA]: 'Một nửa',
};

export const mapResponseVotedHistory: { [key in ResponseVotedHistory]: string } = {
  [ResponseVotedHistory.TANGLEN]: 'Tăng lên',
  [ResponseVotedHistory.GIAMBOT]: 'Giảm bớt',
  [ResponseVotedHistory.GAPDOI]: 'Gấp đôi',
  [ResponseVotedHistory.MOTNUA]: 'Một nửa',
};