import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import Layout from './Layout'
import Banner from './components/Banner'
// import Search from './components/SearchComponents'
import BoxKhuyenMaiDuocYeuThich from './components/BoxKhuyenMaiDuocYeuThich'
import BoxChuyenBayPhoBien from './components/BoxChuyenBayPhoBien'
import KhachHang from './components/KhachHang'
import { CCol, CRow } from '@coreui/react-pro'
import DoiTac from './components/DoiTac'
import Ads from './components/Ads'
import { Link } from 'react-router-dom'
import Sidebar from './components/Sidebar'
import bank from '../../../assets/img/web/bank.jpeg'


const HuongDanThanhToan = () => {
  const dispatch = useDispatch<AppDispatch>()

  return (
    <Layout>
        <div className='py-5' style={{ backgroundColor: '#f7f7f7' }}>

            <div className='container-custom'>

                <CRow>
                    <CCol lg={3}>
                        <Sidebar />
                    </CCol>
                    <CCol lg={9}>
                        <div className="p-3" style={{ background: "#fff" }}>
                            <h3>HƯỚNG DẪN THANH TOÁN</h3>
                            <p>Sau khi đặt vé vé máy bay trực tuyến tại vemaybayviet.com thành công, quý khách sẽ nhận được email của <strong>Vé máy bay ABAY</strong> thông báo chi tiết đơn hàng như mã đặt chỗ, số tiền, thời gian hết hạn giữ vé...</p>
                            <p> Quý khách vui lòng tiến hành thanh toán số tiền được báo trong email <strong>trước khi hết hạn giữ vé.</strong> </p>
                            <p><strong>Vé máy bay ABAY</strong> hỗ trợ các phương thức thanh toán sau:</p>
                            <p>THANH TOÁN TẠI ĐẠI LÝ: Sau khi đặt vé thành công, Quý khách vui lòng qua văn phòng Vé máy bay Việt để thanh toán và nhận vé.</p>
                            <p>THANH TOÁN QUA NGÂN HÀNG: Sau chuyển phí mua vé qua tài khoản, hệ thống Vé máy bay Việt sẽ gửi vé điện tử đến địa chỉ Email. </p>
                            <p>Quý khách vui lòng chuyển khoản đúng <strong>SỐ TIỀN</strong> và <strong>NỘI DUNG</strong> trong email để tránh sai sót. Sau khi chuyển khoản xong, quý khách vui lòng thông báo lại cho <strong>Vé máy bay ABAY</strong> qua số các nhân viên booker để chúng tôi xuất vé cho quý khách. </p>
                            <p>Ngân hàng: <strong>NGÂN HÀNG TMCP QUÂN ĐỘI MBBANK</strong></p>
                            <p>Chủ tài khoản: <strong>CONG TY TNHH ABAY VN</strong></p>
                            <p>Số tài khoản: <strong>5766599999</strong></p>
                            <div className="py-3 text-center">
                                <img style={{maxWidth: 300}} src={bank} />
                            </div>
                        </div>
                    </CCol>
                </CRow>

            </div>

        </div>
      <Ads />
      <DoiTac />
    </Layout>
  )
}

export default HuongDanThanhToan
