import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../reducers';
import { IInitialState, IResponse } from '../../../shared/shared-interfaces';

import {
  createEntity,
  getEntities,
  getEntity,
  IUserParams,
  removeEntity,
  updateDeleteEntity,
  updateEntity,
} from './flightManagement.api';
import { IFlight } from '@/shared/model/flight.model';

interface IUsersInitialState extends IInitialState {
  updateEntityStatusSuccess: boolean;
  filterState: IUserParams;
}

export const initialUsersFilter: IUserParams = {
  page: 1,
  limit: 10,
  sortBy: 'id',
  sortOrder: 'DESC',
};

const initialState: IUsersInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  totalPages: 0,
  updateEntityStatusSuccess: false,
  filterState: initialUsersFilter,
};

export const flightAdapter = createEntityAdapter<IFlight>({
  selectId: ({ id }) => id,
});

const { actions, reducer } = createSlice({
  name: 'flightSlice',
  initialState: flightAdapter.getInitialState({ initialState }),
  reducers: {
    setFilterState(state, { payload }: PayloadAction<IUserParams>) {
      state.initialState.filterState = payload;
    },
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<IFlight[]>>>) => {
        flightAdapter.setAll(state, payload.data.data);
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(getEntities.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    });

    builder.addCase(getEntity.fulfilled.type, (state, { payload }: PayloadAction<IFlight>) => {
      flightAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(getEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    });
    builder.addCase(updateEntity.fulfilled.type, (state, { payload }: PayloadAction<IFlight>) => {
      flightAdapter.updateOne(state, { id: payload.id, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(updateEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    });
    builder.addCase(createEntity.fulfilled.type, (state, { payload }: PayloadAction<IFlight>) => {
      flightAdapter.addOne(state, payload);
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(createEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    });
    builder.addCase(removeEntity.fulfilled.type, (state, { payload }: PayloadAction<string>) => {
      flightAdapter.removeOne(state, payload);
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(removeEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    });
    builder.addCase(updateDeleteEntity.fulfilled.type, (state, { payload }: PayloadAction<string[]>) => {
      flightAdapter.removeMany(state, payload);
      state.initialState.totalItems -= payload.length;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(updateDeleteEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    });
  },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const flightSelectors = flightAdapter.getSelectors<RootState>((state) => state.flightReducer);

const { selectById } = flightAdapter.getSelectors();
const getFlightState = (rootState: RootState) => rootState.flightReducer;

export const selectEntityById = (id: number) => {
  return createSelector(getFlightState, (state) => selectById(state, id));
};
