import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store'
import Layout from './Layout'
import Banner from './components/Banner'
import BoxKhuyenMaiDuocYeuThich from './components/BoxKhuyenMaiDuocYeuThich'
import BoxChuyenBayPhoBien from './components/BoxChuyenBayPhoBien'
import KhachHang from './components/KhachHang'
import DoiTac from './components/DoiTac'
import Ads from './components/Ads'
import DataComSearchPlugin from './components/DataComSearchPlugin'
// import DtcSearch from './components/DtcSearch'
// import DataComSearchPlugin from './components/DataComSearchPlugin'

const Home = () => {
  const dispatch = useDispatch<AppDispatch>()



  return (
    <Layout>
      <Banner />
      <div className='container-custom' style={{ position: "relative", top: -100}}>
        <DataComSearchPlugin />
      </div>
      {/* <DtcSearch /> */}
    

      
      {/* <Filter /> */}
      {/* <Search /> */}
      <BoxKhuyenMaiDuocYeuThich />
      <BoxChuyenBayPhoBien />
      <KhachHang />
      <Ads />
      <DoiTac />
    </Layout>
  )
}

export default Home
