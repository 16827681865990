import React, { useEffect } from 'react';

const DataComPlugin = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.charset = 'UTF-8';
    script.innerHTML = `var dtc_result = { path: ("https:" == document.location.protocol ? "https://" : "http://") + "plugin.datacom.vn", productKey: "ue09owepch7125o", languageCode: "vi" };`;
    document.head.appendChild(script);

    var dtc_result = { path: ("https:" == document.location.protocol ? "https://" : "http://") + "plugin.datacom.vn", productKey: "ue09owepch7125o", languageCode: "vi" };
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = `${dtc_result?.path}/Resources/Static/Js/plugin.js?v=${new Date().getTime()}`;
    script1.charset = 'UTF-8';
    script1.innerHTML = `var dtc_result = { path: ("https:" == document.location.protocol ? "https://" : "http://") + "plugin.datacom.vn", productKey: "ue09owepch7125o", languageCode: "vi" };`;
    document.head.appendChild(script1);

    // Clean up the script when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return <div id='dtc-result'></div>;
};

export default DataComPlugin;
