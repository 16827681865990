import { Link } from "react-router-dom"

const Sidebar = () => {
    const menu1 = [
        { title: 'Giới thiệu', path: '/gioi-thieu' },
        { title: 'Hướng dẫn thanh toán', path: '/huong-dan-thanh-toan' },
        { title: 'Hướng dẫn đặt vé', path: '/huong-dan-thanh-toan' },
        { title: 'Chính sách hoàn tiền', path: '/huong-dan-thanh-toan' },
        { title: 'Chính sách đại lý', path: '/huong-dan-thanh-toan' },
        { title: 'Chính sách bảo mật', path: '/huong-dan-thanh-toan' },
        { title: 'Điều khoản & điều kiện', path: '/huong-dan-thanh-toan' },
        { title: 'Câu hỏi thường gặp', path: '/huong-dan-thanh-toan' },
        { title: 'Liên hệ', path: '/contact' },
      ]
    return   <div className="d-flex flex-column gap-2 p-3" style={{ background: "#fff" }}>
            {
                menu1.map( (item, index) => {
                    return <Link key={index} to={item.path} className="text-black text-decoration-none d-flex gap-2 align-items-center" style={{ fontSize: 14 }}>
                    <i className="fas fa-angle-right text-black"></i>
                    {item.title}
                </Link>
                } )
            }
        </div>
}
export default Sidebar