import {
  CButton,
  CCol,
  CDatePicker,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
  CTimePicker,
} from '@coreui/react-pro';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import SModal from '../../shared/Modal/SModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { AppDispatch } from '../../../store';
import { ToastSuccess } from '../../shared/toast/Toast';
import { createEntity, updateEntity } from './flightManagement.api';
import { fetching, resetEntity } from './flightManagement.reducer';
import { IFlight, INewFlight } from '@/shared/model/flight.model';
import { ISelectValue } from '@/shared/shared-interfaces';
import Select, { SingleValue, components } from 'react-select'
import { IHangBay } from '@/shared/model/hangbay.model';
import { hangbaySelectors } from '../HangBayManagement/hangbayManagement.reducer';
import { getEntities as getHangBay } from '../HangBayManagement/hangbayManagement.api';
import { getEntities as getSanBay } from '../SanBayManagement/sanbayManagement.api';
import dayjs from 'dayjs';
import { sanbaySelectors } from '../SanBayManagement/sanbayManagement.reducer';
import { ISanBay } from '@/shared/model/sanbay.model';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IUserUpdateProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  userObj?: IFlight;
}

const UserUpdate = (props: IUserUpdateProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { initialState } = useSelector((state: RootState) => state.flightReducer);
  const { updateEntitySuccess } = initialState;
  const { visible, setVisible, userObj } = props;
  const handleOnClose = () => {
    setVisible(false);
  };

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);

  useEffect(() => {
    if (updateEntitySuccess) {
      setVisible(false);
      ToastSuccess(userObj ? 'Cập nhật chuyến bay thành công' : 'Thêm chuyến bay thành công');
      dispatch(resetEntity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEntitySuccess]);

  const initialValues: INewFlight = {
    tenChuyenBay: "", // Tên chuyến bay hoặc mã chuyến bay
    tenMayBay: "", // Tên máy bay
    maChuyenBay: "", // Mã chuyến bay
    ngayBay: dayjs().format('YYYY/MM/DD'), // Ngày bay
    ThoiGianBatDauBay: '', 
    ThoiGianKetThucBay:  '', 
    ThoiGianBay: "",
    noiDiId: 0, // Nơi đi
    noiDenId: 0, // Nơi đến
    giaVe: 0, // Giá vé
    hangMayBayId: 0, // hãng máy bay
    status: 1,
  };

  const validationSchema = Yup.object().shape({
    tenChuyenBay: Yup.string() .trim().required('Không được để trống'),
    tenMayBay: Yup.string().trim() .required('Không được để trống'),
    maChuyenBay: Yup.string().trim().required('Không được để trống'),
    ngayBay: Yup.string().trim() .required('Không được để trống'),
    ThoiGianBatDauBay: Yup.string().trim().required('Không được để trống'),
    ThoiGianKetThucBay: Yup.string().trim().required('Không được để trống'),
    ThoiGianBay: Yup.string().trim().required('Không được để trống'),
    noiDiId: Yup.number().required('Không được để trống'),
    noiDenId: Yup.number().required('Không được để trống'),
    giaVe: Yup.number().required('Không được để trống'),
    hangMayBayId: Yup.number().required('Không được để trống'),
  });


  // tenMayBay: string; // Tên máy bay
  // maChuyenBay: string; // Mã chuyến bay
  // ngayBay: string; // Ngày bay
  // ThoiGianBatDauBay: string; // Giờ bay 22:00 - 01:00
  // ThoiGianKetThucBay: string;
  // noiDi: string; // Nơi đi
  // noiDen: string; // Nơi đến
  // giaVe: number; // Giá vé
  // hangMayBayId: number; // hãng máy bay
  // sanBayId: number; // Sân bay
  // hangMayBay?: any;
  // status: number;

  const hangbays = useSelector(hangbaySelectors.selectAll)

  useEffect(() => {
    dispatch(getHangBay({ limit: 20, page: 1 }));
  }, [])


  const returnHangBayItem = (items: IHangBay[], selecthangBay?: IHangBay | null) => {
    return items.map((item) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  }


  const sanbays = useSelector(sanbaySelectors.selectAll)

  useEffect(() => {
    dispatch(getSanBay({ limit: 80, page: 1 }));
  }, [])


  const returnSanBayItem = (items: ISanBay[], selecthangBay?: ISanBay | null) => {
    return items.map((item) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  }


  const returnOption = (
    optionValue: number | null | undefined,
    array: ISelectValue<string>[],
  ) => {
    if (!optionValue) return null
    if (!array.length) return null
    return array.find(({ value }) => Number(value) === Number(optionValue))
  }

  const customBankItems: any = returnHangBayItem(hangbays)

  const customSanBayItems: any = returnSanBayItem(sanbays)


  const formatTime  = () => {
    
  } 

  return (
    <SModal visible={visible} onClose={handleOnClose} size="xl" backdrop="static" className="custom-modal">
      <Formik
        initialValues={userObj || initialValues}
        validationSchema={validationSchema}
        // validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(value) => {
          dispatch(fetching());
          if (userObj) {
            dispatch(updateEntity(value as IFlight));
          } else {
            dispatch(createEntity(value));
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm, setFieldValue }) => (
          <CForm onSubmit={handleSubmit} className="custom-form">
            <>
              <CModalHeader>{userObj ? 'Chỉnh sửa chuyến bay' : 'Thêm chuyến bay mới'}</CModalHeader>
              <CModalBody>
                <CRow className="g-3">
                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">Tên chuyến bay</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="tenChuyenBay"
                      autoComplete="off"
                      value={values.tenChuyenBay}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.tenChuyenBay && touched.tenChuyenBay}
                    />
                    <CFormFeedback invalid className={!!errors.tenChuyenBay && touched.tenChuyenBay ? 'd-block' : 'd-none'}>
                      {errors.tenChuyenBay}
                    </CFormFeedback>
                  </CCol>


                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">Mã chuyến bay</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="maChuyenBay"
                      autoComplete="off"
                      value={values.maChuyenBay}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.maChuyenBay && touched.maChuyenBay}
                    />
                    <CFormFeedback invalid className={!!errors.maChuyenBay && touched.maChuyenBay ? 'd-block' : 'd-none'}>
                      {errors.maChuyenBay}
                    </CFormFeedback>
                  </CCol>


                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">Hãng máy bay</CFormLabel>
                    <Select
                      defaultValue={null}
                      className="custom-select form-height-44"
                      classNamePrefix="react-select"
                      value={
                        returnOption(
                          values.hangMayBayId as any,
                          customBankItems,
                        ) as any
                      }
                      onChange={(
                        newValue: SingleValue<ISelectValue<string>>,
                      ) => {
                        setFieldValue(`hangMayBayId`, newValue?.value || '')
                      }}
                      noOptionsMessage={() => <>Không có hãng máy bay nào</>}
                      id={'hangMayBayId'}
                      options={customBankItems}
                      placeholder={'Chọn Hãng máy bay'}
                      isClearable={true}
                      closeMenuOnSelect={true}
                      menuPosition={'absolute'}
                      name="hangMayBayId"
                    />
                    <CFormFeedback invalid className={!!errors.tenMayBay && touched.tenMayBay ? 'd-block' : 'd-none'}>
                      {errors.tenMayBay}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">Tên máy bay</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="tenMayBay"
                      autoComplete="off"
                      value={values.tenMayBay}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.tenMayBay && touched.tenMayBay}
                    />
                    <CFormFeedback invalid className={!!errors.tenMayBay && touched.tenMayBay ? 'd-block' : 'd-none'}>
                      {errors.tenMayBay}
                    </CFormFeedback>
                  </CCol>


                  <CCol xs="3">
                    <CFormLabel className="mb-6 label-gray-700">Ngày bay</CFormLabel>
                    <CDatePicker date={values.ngayBay} locale="vi-VN" placeholder="Chọn ngày bay" onDateChange={(date) => setFieldValue('ngayBay', dayjs(date).format('YYYY/MM/DD'))} />
                    <CFormFeedback invalid className={!!errors.ngayBay && touched.ngayBay ? 'd-block' : 'd-none'}>
                      {errors.ngayBay}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="3">
                    <CFormLabel className="mb-6 label-gray-700">Thời gian bắt đầu bay</CFormLabel>
                    <CTimePicker time={values.ThoiGianBatDauBay} locale="vi-VN" placeholder="Chọn thời gian bắt đầu bay" onTimeChange={(timeString: any, localeTimeString?: string, date?: Date) => setFieldValue('ThoiGianBatDauBay', dayjs(date).format('HH:mm:ss'))} />
                    <CFormFeedback invalid className={!!errors.ThoiGianBatDauBay && touched.ThoiGianBatDauBay ? 'd-block' : 'd-none'}>
                      {errors.ThoiGianBatDauBay}
                    </CFormFeedback>
                  </CCol>


                  <CCol xs="3">
                    <CFormLabel className="mb-6 label-gray-700">Thời gian kết thúc bay</CFormLabel>
                    <CTimePicker time={values.ThoiGianKetThucBay}  locale="vi-Vn" placeholder="Chọn thời gian kết thúc bay" onTimeChange={(timeString: any, localeTimeString?: string, date?: Date) => setFieldValue('ThoiGianKetThucBay', dayjs(date).format('HH:mm:ss'))} />
                    <CFormFeedback invalid className={!!errors.ThoiGianKetThucBay && touched.ThoiGianKetThucBay ? 'd-block' : 'd-none'}>
                      {errors.ThoiGianKetThucBay}
                    </CFormFeedback>
                  </CCol>


                  <CCol xs="3">
                    <CFormLabel className="mb-6 label-gray-700">Thời gian bay</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="ThoiGianBay"
                      autoComplete="off"
                      value={values.ThoiGianBay}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.ThoiGianBay && touched.ThoiGianBay}
                    />
                    <CFormFeedback invalid className={!!errors.ThoiGianBay && touched.ThoiGianBay ? 'd-block' : 'd-none'}>
                      {errors.ThoiGianBay}
                    </CFormFeedback>
                  </CCol>


                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">Điểm xuất phát</CFormLabel>
                    <Select
                      defaultValue={null}
                      className="custom-select form-height-44"
                      classNamePrefix="react-select"
                      value={
                        returnOption(
                          values.noiDiId as any,
                          customSanBayItems,
                        ) as any
                      }
                      onChange={(
                        newValue: SingleValue<ISelectValue<string>>,
                      ) => {
                        setFieldValue(`noiDiId`, newValue?.value || '')
                      }}
                      noOptionsMessage={() => <>Không sân bay nào</>}
                      id={'noiDiId'}
                      options={customSanBayItems}
                      placeholder={'Chọn điểm xuất phát'}
                      isClearable={true}
                      closeMenuOnSelect={true}
                      menuPosition={'absolute'}
                      name="noiDiId"
                    />
                    <CFormFeedback invalid className={!!errors.noiDiId && touched.noiDiId ? 'd-block' : 'd-none'}>
                      {errors.noiDiId}
                    </CFormFeedback>
                  </CCol>

                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">Điểm đến</CFormLabel>
                    <Select
                      defaultValue={null}
                      className="custom-select form-height-44"
                      classNamePrefix="react-select"
                      value={
                        returnOption(
                          values.noiDenId as any,
                          customSanBayItems,
                        ) as any
                      }
                      onChange={(
                        newValue: SingleValue<ISelectValue<string>>,
                      ) => {
                        setFieldValue(`noiDenId`, newValue?.value || '')
                      }}
                      noOptionsMessage={() => <>Không sân bay nào</>}
                      id={'noiDenId'}
                      options={customSanBayItems}
                      placeholder={'Chọn điểm đến'}
                      isClearable={true}
                      closeMenuOnSelect={true}
                      menuPosition={'absolute'}
                      name="noiDenId"
                    />
                    <CFormFeedback invalid className={!!errors.noiDenId && touched.noiDenId ? 'd-block' : 'd-none'}>
                      {errors.noiDenId}
                    </CFormFeedback>
                  </CCol>


                  <CCol xs="6">
                    <CFormLabel className="mb-6 label-gray-700">Giá vé từ</CFormLabel>
                    <CFormInput
                      className="form-height-44"
                      name="giaVe"
                      autoComplete="off"
                      value={values.giaVe}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={!!errors.giaVe && touched.giaVe}
                      type="number"
                    />
                    <CFormFeedback invalid className={!!errors.giaVe && touched.giaVe ? 'd-block' : 'd-none'}>
                      {errors.giaVe}
                    </CFormFeedback>
                  </CCol>

                </CRow>
              </CModalBody>
              <CModalFooter className="d-flex justify-content-end">
                <CButton
                  className="btn-custom minw-120 variant-gray-300"
                  type="button"
                  onClick={() => {
                    resetForm();
                    setVisible(false);
                  }}
                >
                  Huỷ
                </CButton>
                <CButton className="btn-custom minw-120 primary-500" type="submit">
                  Lưu
                </CButton>
              </CModalFooter>
            </>
          </CForm>
        )}
      </Formik>
    </SModal>
  );
};

export default UserUpdate;
