import { useRouter } from '@/shared/utils/hooks/useRouter';
import React, { useEffect } from 'react';

const DataComSearchPlugin = () => {
    const { location } = useRouter();
  useEffect(() => {
    var dtc_search = {
        path: (document.location.protocol === "https:" ? "https://" : "http://") + "plugin.datacom.vn",
        productKey: "ue09owepch7125o",
        languageCode: "vi",
      };

      const script = document.createElement('script');
      script.innerHTML = `var dtc_search = {
        path: (document.location.protocol === "https:" ? "https://" : "http://") + "plugin.datacom.vn",
        productKey: "ue09owepch7125o",
        languageCode: "vi",
      };`;

      document.head.appendChild(script);

      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = `${dtc_search.path}/Resources/Static/Js/plugin.js?v=${new Date().getTime()}`;
      script1.charset = 'UTF-8';

      // Append the script to the head
      document.head.appendChild(script1);

      // Cleanup the script tag when the component unmounts
      return () => {
        document.head.removeChild(script);
        document.head.removeChild(script1);
      };
  }, [location.pathname]);  // Chỉ thực hiện lần đầu khi component mount

  return <div id="dtc-search"></div>;  // No UI is rendered by this component
};

export default DataComSearchPlugin;
