import { CCol, CRow } from "@coreui/react-pro"
import hotline from '../../../../assets/img/web/hotline.svg'

const Footer = () => {

    const menu1 = [
        { title: 'Giới thiệu', path: '/' },
        { title: 'Tin tức', path: '/' },
        { title: 'Tin khuyến mại', path: '/' },
        { title: 'Câu hỏi thường gặp', path: '/' },
        { title: 'Tuyển dụng', path: '/' },
        { title: 'Liên hệ', path: '/' },
    ]

    const menu2 = [
        { title: 'Hướng dẫn thanh toán', path: '/' },
        { title: 'Hướng dẫn đặt vé', path: '/' },
        { title: 'Chính sách hoàn tiền', path: '/' },
        { title: 'Chính sách đại lý', path: '/' },
        { title: 'Điều khoản & điều kiện', path: '/' },
        { title: 'Chính sách bảo mật', path: '/' },
    ]

    return <>
        <div className="footer-custom py-5" style={{ background: 'linear-gradient(263.81deg, #26bed6 -3.68%, #1bb5ec 108.73%)' }}>
            <div className="container-custom">
                <CRow className="g-3">
                    <CCol md={4}>
                        <div>
                            <h4 className="text-white text-uppercase">Thông tin liên hệ</h4>
                            <div className="mt-3 text-white">
                                <div style={{ fontSize: 14 }}>CÔNG TY TNHH ABAY VIỆT NAM</div>
                                <div className="mt-1" style={{ fontSize: 14 }}>Số ĐKKD 0106400583 - Cấp ngày 25/12/2013</div>
                                <div className="mt-1" style={{ fontSize: 14 }}>324 Phố Huế, Q.Hai Bà Trưng, Hà Nội</div>
                                <div className="mt-1 d-flex gap-2 align-items-center" style={{ fontSize: 14 }}>
                                <i className="fas fa-phone-alt mr-5"></i>
                                Điện thoại: 1900 252591</div>
                                <div className="mt-1 d-flex gap-2 align-items-center" style={{ fontSize: 14 }}>
                                <i className="fas fa-envelope mr-5"></i>
                                Email: dailyve6868@gmail.com</div>
                            </div>
                        </div>
                    </CCol>


                    <CCol md={8}>
                       <CRow className="g-3">
                            <CCol md={4}>
                                <div>
                                    <h4 className="text-white text-uppercase">Về chúng tôi</h4>

                                  

                                    <div className="mt-3 d-flex flex-column gap-2">
                                        {
                                            menu1.map( (item, index) => {
                                                return <a key={index} href={item.path} className="text-white text-decoration-none d-flex gap-2 align-items-center" style={{ fontSize: 14 }}>
                                                <i className="fas fa-angle-right text-white"></i>
                                                {item.title}
                                            </a>
                                            } )
                                        }
                                    </div>
                                    
                                </div>
                            </CCol>
                            <CCol md={4}>
                                <div>
                                    <h4 className="text-white text-uppercase">Trợ giúp</h4>

                                    <div className="mt-3 d-flex flex-column gap-2">
                                        {
                                            menu2.map( (item, index) => {
                                                return <a key={index} href={item.path} className="text-white text-decoration-none d-flex gap-2 align-items-center" style={{ fontSize: 14 }}>
                                                <i className="fas fa-angle-right text-white"></i>
                                                {item.title}
                                            </a>
                                            } )
                                        }
                                    </div>

                                </div>
                            </CCol>
                            <CCol md={4}>
                                <div>
                                    <h4 className="text-white text-uppercase mb-0">Liên hệ</h4>

                                    <div className="mt-3 d-flex flex-column gap-2 bg-white p-3" style={{ padding: 10, borderRadius: 5, border: "1px dashed #eb1f26" }}>
                                        <h6 style={{ color: "#eb1f26" }}>Bạn cần giúp đỡ</h6>

                                        <div className="d-flex gap-2 align-items-start">
                                            <img src={hotline}/>

                                            <div>
                                                <div style={{ fontSize: 14 }}>Chúng tôi hỗ trợ <strong>24/7</strong></div>
                                                <div style={{ fontSize: 14 }}>Hotline: 1900 252591</div>
                                                <div style={{ fontSize: 14 }}>dailyve6868@gmail.com</div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>
                            </CCol>
                       </CRow>
                    </CCol>
                </CRow>
            </div>
        </div>
    </>
}

export default Footer