import { combineReducers } from 'redux';
import container from './components/containers/reducer';
import messageReducer from './components/modules/Chat/Message/message.reducer';
import chatReducer from './components/modules/Chat/chat.reducer';
import usersReducer from './components/modules/UserManagement/usersManagement.reducer';
import authentication from './components/modules/auth/auth.reducer';
import gameReducer from './components/modules/GameManagement/gameManagement.reducer';
import historyReducer from './components/modules/HistoryManagement/historyManagement.reducer';
import transactionReducer from './components/modules/TransactionManagement/transactionManagement.reducer';
import rechargeReducer from './components/modules/RechargeManagement/rechargeManagement.reducer';
import sanbayReducer from './components/modules/SanBayManagement/sanbayManagement.reducer';
import hangbayReducer from './components/modules/HangBayManagement/hangbayManagement.reducer';
import flightReducer from './components/modules/FlightManagement/flightManagement.reducer';
import veReducer from './components/modules/VeManagement/veManagement.reducer';

const rootReducer = combineReducers({
  container,
  authentication,
  usersReducer,
  messageReducer,
  chatReducer,
  gameReducer,
  historyReducer,
  transactionReducer,
  rechargeReducer,
  sanbayReducer,
  hangbayReducer,
  flightReducer,
  veReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
